import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  Dialog,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
  CircularProgress,
  Checkbox,
  Tooltip,
  ClickAwayListener,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
  HelpOutline,
} from "@material-ui/icons";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import timezones from "timezones-list";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  formControl: {
    minWidth: 120,
    marginRight: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dragItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  progress: {
    margin: "auto",
    textAlign: "center",
  },
}));

const AutoFollowUpRuleSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  conditions: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Type is required"),
      operator: Yup.string().required("Operator is required"),
      value: Yup.string().required("Value is required"),
      deleteOnNewMessage: Yup.boolean().optional(),
    })
  ),
  executionType: Yup.string().required("Execution type is required"),
  timezone: Yup.string().optional(),
  years: Yup.number()
    .typeError("Years must be a number")
    .when("executionType", {
      is: "once",
      then: () =>
        Yup.number()
          .required("Years is required")
          .min(2024, "Years must be 2024 - 9999")
          .max(9999, "Years must be 2024- 9999"),
      otherwise: () => Yup.number().optional().min(0),
    }),
  months: Yup.number()
    .typeError("Months must be a number")
    .when("executionType", {
      is: "once",
      then: () =>
        Yup.number()
          .required("Months is required")
          .min(1, "Months must be 1 - 12")
          .max(12, "Months must be 1 - 12"),
      otherwise: () => Yup.number().optional().min(0),
    }),
  days: Yup.number()
    .typeError("Days must be a number")
    .when("executionType", {
      is: "nDaily",
      then: () =>
        Yup.number()
          .required("Days is required")
          .min(1, "Days must be 1 - 31")
          .max(31, "Days must be 1 - 31"),
      otherwise: () => Yup.number().optional().min(0),
    }),
  hours: Yup.number()
    .typeError("Hours must be a number")
    .min(0, "Hours must be 0 - 23")
    .max(23, "Hours must be 0 - 23")
    .required("Hours is required"),
  minutes: Yup.number()
    .typeError("Minutes must be a number")
    .min(0, "Minutes must be 0 - 59")
    .max(59, "Minutes must be 0 - 59")
    .required("Minutes is required"),
  followUpMessage: Yup.string()
    .min(3, "Too Short! Minimum 3 characters")
    .required("Follow up message is required"),
  actions: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Type is required"),
      operation: Yup.string().required("Operation is required"),
      fromValue: Yup.string().when("operation", {
        is: "updateLabel",
        then: () => Yup.string().required("From is required"),
        otherwise: () => Yup.string().optional(),
      }),
      toValue: Yup.string().required("To is required"),
    })
  ),
});

const DEFAULT_STATE = {
  name: "",
  conditions: [
    {
      type: "labels",
      operator: "equals",
      value: "",
      deleteOnNewMessage: false,
    },
  ],
  executionType: "daily",
  timezone: "Asia/Kuala_Lumpur",
  days: 3,
  hours: 1,
  minutes: 30,
  years: new Date().getFullYear(),
  months: new Date().getMonth() + 1,
  followUpMessage: "",
  actions: [],
};

const AutoFollowUpRuleModal = ({ open, onClose, autoFollowUpRuleId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tooltipOpenArray, setTooltipOpenArray] = useState([]);
  const [rule, setRule] = useState(DEFAULT_STATE);

  useEffect(() => {
    if (!open || !autoFollowUpRuleId) {
      setRule(DEFAULT_STATE);
      return;
    }

    const debounce = setTimeout(async () => {
      try {
        setLoading(true);
        const { data } = await api.get(
          `/auto-follow-up-rule/${autoFollowUpRuleId}`
        );

        const conditions = [];
        const actions = [];

        if (
          data?.autoFollowUpConditions &&
          data?.autoFollowUpConditions.length > 0
        ) {
          data?.autoFollowUpConditions.forEach((condition) => {
            conditions.push({
              id: condition?.id,
              type: condition?.type,
              operator: condition?.operator,
              value: condition?.value,
              deleteOnNewMessage: condition?.deleteOnNewMessage,
            });
          });
        }

        if (data?.autoFollowUpActions && data?.autoFollowUpActions.length > 0) {
          data?.autoFollowUpActions.forEach((action) => {
            actions.push({
              id: action?.id,
              type: action?.type,
              operation: action?.operation,
              fromValue: action?.fromValue,
              toValue: action?.toValue,
            });
          });
        }

        setRule({
          name: data?.name,
          conditions,
          days: data?.days,
          hours: data?.hours,
          minutes: data?.minutes,
          years: data?.years === 0 ? new Date().getFullYear() : data?.years,
          months: data?.months === 0 ? new Date().getMonth() + 1 : data?.months,
          executionType: data?.executionType,
          timezone: data?.timezone || "Asia/Kuala_Lumpur",
          followUpMessage: data?.message,
          actions,
        });
      } catch (err) {
        toastError(err);
        setRule(DEFAULT_STATE);
      } finally {
        setLoading(false);
      }
    }, 500);
    return () => clearTimeout(debounce);
  }, [autoFollowUpRuleId, open]);

  const handleClose = () => {
    setRule(DEFAULT_STATE);
    onClose();
  };

  const handleSubmit = async (value) => {
    try {
      if (autoFollowUpRuleId) {
        await api.put(`/auto-follow-up-rule/${autoFollowUpRuleId}`, value);

        toast.success(i18n.t("AutoFollowUpRule.toasts.updated"));
      } else {
        await api.post("/auto-follow-up-rule", value);

        toast.success(i18n.t("AutoFollowUpRule.toasts.created"));
      }
      onClose();
    } catch (error) {
      toastError(error);
    }
  };

  const handleTooltipClose = () => {
    setTooltipOpenArray([]);
  };

  const handleTooltipToggle = (index) => {
    setTooltipOpenArray((prev) => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    });
  };

  const TooltipHelp = ({
    tooltipOpen,
    handleTooltipClose,
    handleTooltipToggle,
  }) => (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{ disablePortal: true }}
          onClose={handleTooltipClose}
          open={tooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={i18n.t(
            "autoFollowUpRuleModal.form.conditions.deleteOnNewMessageHelp"
          )}
        >
          <HelpOutline
            fontSize="small"
            style={{ marginLeft: 4, cursor: "pointer" }}
            onClick={handleTooltipToggle}
          />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );

  const generateExplanation = (values) => {
    if (values.executionType === "daily") {
      const time = `${values.hours.toString().padStart(2, "0")}:${values.minutes
        .toString()
        .padStart(2, "0")}`;
      return `${i18n.t("autoFollowUpRuleModal.explanation.daily", { time })}`;
    } else if (values.executionType === "nDaily") {
      const time = `${values.hours.toString().padStart(2, "0")}:${values.minutes
        .toString()
        .padStart(2, "0")}`;
      return `${i18n.t("autoFollowUpRuleModal.explanation.nDaily", {
        days: values.days,
        time,
      })}`;
    }
    return "";
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={() => onClose(false)}
      >
        <DialogTitle>
          {autoFollowUpRuleId
            ? `${i18n.t("autoFollowUpRuleModal.title.edit")}`
            : `${i18n.t("autoFollowUpRuleModal.title.add")}`}
        </DialogTitle>
        {autoFollowUpRuleId && loading ? (
          <DialogContent dividers>
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          </DialogContent>
        ) : (
          <>
            <Formik
              initialValues={rule}
              enableReinitialize={true}
              validationSchema={AutoFollowUpRuleSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSubmit(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                <Form>
                  <DialogContent dividers>
                    <Field
                      as={TextField}
                      fullWidth
                      label={i18n.t("autoFollowUpRuleModal.form.name")}
                      autoFocus
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      required
                    />

                    <Field
                      as={TextField}
                      fullWidth
                      label={i18n.t(
                        "autoFollowUpRuleModal.form.followUpMessage"
                      )}
                      autoFocus
                      name="followUpMessage"
                      error={
                        touched.followUpMessage &&
                        Boolean(errors.followUpMessage)
                      }
                      helperText={
                        touched.followUpMessage && errors.followUpMessage
                      }
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      multiline
                      minRows={6}
                      required
                    />
                    <Typography variant="caption">
                      {i18n
                        .t(
                          "autoFollowUpRuleModal.form.prefillVariablesExplanation"
                        )
                        .replace(/\{namePlaceholder\}/g, "{{name}}")
                        .replace(/\{emailPlaceholder\}/g, "{{email}}")
                        .replace(/\{info.shortName\}/g, "{{info.shortName}}")
                        .replace(/\{info.urlPlaceholder\}/g, "{{{info.urlPlaceholder}}}")}
                    </Typography>

                    <Divider className={classes.divider} />
                    <Typography variant="body1">
                      {i18n.t("autoFollowUpRuleModal.form.time.title")}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {i18n.t("autoFollowUpRuleModal.form.time.description")}
                    </Typography>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      style={{ marginTop: 4, marginBottom: 4 }}
                    >
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={Select}
                          fullWidth
                          name="executionType"
                          label={i18n.t(
                            "autoFollowUpRuleModal.form.executionType.title"
                          )}
                        >
                          <MenuItem value="daily">
                            {i18n.t(
                              "autoFollowUpRuleModal.form.executionType.daily"
                            )}
                          </MenuItem>
                          <MenuItem value="nDaily">
                            {i18n.t(
                              "autoFollowUpRuleModal.form.executionType.nDaily"
                            )}
                          </MenuItem>
                          <MenuItem value="once">
                            {i18n.t(
                              "autoFollowUpRuleModal.form.executionType.once"
                            )}
                          </MenuItem>
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={Select}
                          fullWidth
                          name="timezone"
                          label={i18n.t(
                            "autoFollowUpRuleModal.form.timezone.title"
                          )}
                        >
                          {timezones &&
                            timezones.length > 0 &&
                            timezones.map((timezone, index) => (
                              <MenuItem key={index} value={timezone.tzCode}>
                                {timezone.label}
                              </MenuItem>
                            ))}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        {values.executionType === "daily" && (
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Field
                                as={TextField}
                                fullWidth
                                label={i18n.t(
                                  "autoFollowUpRuleModal.form.hours"
                                )}
                                name="hours"
                                type="number"
                                error={touched.hours && Boolean(errors.hours)}
                                helperText={touched.hours && errors.hours}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Field
                                as={TextField}
                                fullWidth
                                label={i18n.t(
                                  "autoFollowUpRuleModal.form.minutes"
                                )}
                                name="minutes"
                                type="number"
                                error={
                                  touched.minutes && Boolean(errors.minutes)
                                }
                                helperText={touched.minutes && errors.minutes}
                              />
                            </Grid>
                          </Grid>
                        )}
                        {values.executionType === "nDaily" && (
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Field
                                as={TextField}
                                fullWidth
                                label={i18n.t(
                                  "autoFollowUpRuleModal.form.days"
                                )}
                                name="days"
                                type="number"
                                error={touched.days && Boolean(errors.days)}
                                helperText={touched.days && errors.days}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Field
                                as={TextField}
                                fullWidth
                                label={i18n.t(
                                  "autoFollowUpRuleModal.form.hours"
                                )}
                                name="hours"
                                type="number"
                                error={touched.hours && Boolean(errors.hours)}
                                helperText={touched.hours && errors.hours}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Field
                                as={TextField}
                                fullWidth
                                label={i18n.t(
                                  "autoFollowUpRuleModal.form.minutes"
                                )}
                                name="minutes"
                                type="number"
                                error={
                                  touched.minutes && Boolean(errors.minutes)
                                }
                                helperText={touched.minutes && errors.minutes}
                              />
                            </Grid>
                          </Grid>
                        )}
                        {values.executionType === "once" && (
                          <Grid container spacing={2}>
                            <Grid item xs={3}>
                              <Field
                                as={TextField}
                                fullWidth
                                label={i18n.t(
                                  "autoFollowUpRuleModal.form.years"
                                )}
                                name="years"
                                type="number"
                                error={touched.years && Boolean(errors.years)}
                                helperText={touched.years && errors.years}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Field
                                as={TextField}
                                fullWidth
                                label={i18n.t(
                                  "autoFollowUpRuleModal.form.months"
                                )}
                                name="months"
                                type="number"
                                error={touched.months && Boolean(errors.months)}
                                helperText={touched.months && errors.months}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Field
                                as={TextField}
                                fullWidth
                                label={i18n.t(
                                  "autoFollowUpRuleModal.form.days"
                                )}
                                name="days"
                                type="number"
                                error={touched.days && Boolean(errors.days)}
                                helperText={touched.days && errors.days}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Field
                                as={TextField}
                                fullWidth
                                label={i18n.t(
                                  "autoFollowUpRuleModal.form.hours"
                                )}
                                name="hours"
                                type="number"
                                error={touched.hours && Boolean(errors.hours)}
                                helperText={touched.hours && errors.hours}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Field
                                as={TextField}
                                fullWidth
                                label={i18n.t(
                                  "autoFollowUpRuleModal.form.minutes"
                                )}
                                name="minutes"
                                type="number"
                                error={
                                  touched.minutes && Boolean(errors.minutes)
                                }
                                helperText={touched.minutes && errors.minutes}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Typography variant="body2" color="textSecondary">
                      {generateExplanation(values)}
                    </Typography>

                    <Divider className={classes.divider} />
                    <Typography variant="body1">
                      {i18n.t("autoFollowUpRuleModal.form.conditions.title")}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {i18n.t(
                        "autoFollowUpRuleModal.form.conditions.description"
                      )}
                    </Typography>
                    <FieldArray name="conditions">
                      {({ move, remove, push }) => (
                        <>
                          <DragDropContext
                            onDragEnd={({ source, destination }) => {
                              if (destination) {
                                move(source.index, destination.index);
                              }
                            }}
                          >
                            <Droppable droppableId="conditions">
                              {(provided) => (
                                <List
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {values.conditions.map((condition, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`condition-${index}`}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <ListItem
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className={classes.dragItem}
                                          style={{
                                            ...provided.draggableProps.style,
                                            backgroundColor: snapshot.isDragging
                                              ? "#f0f0f0"
                                              : "white",
                                            boxShadow: snapshot.isDragging
                                              ? "0 0 0 1px rgba(0,0,0,0.1)"
                                              : "none",
                                          }}
                                        >
                                          <IconButton
                                            {...provided.dragHandleProps}
                                            size="small"
                                          >
                                            <DragIcon />
                                          </IconButton>
                                          <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                          >
                                            <Grid item xs={12} sm={3}>
                                              <FormControl
                                                fullWidth
                                                className={classes.formControl}
                                              >
                                                <InputLabel>Type</InputLabel>
                                                <Field
                                                  as={Select}
                                                  name={`conditions.${index}.type`}
                                                  onChange={(e) => {
                                                    const newType =
                                                      e.target.value;
                                                    setFieldValue(
                                                      `conditions.${index}.type`,
                                                      newType
                                                    );

                                                    // Reset operator and value based on the new type
                                                    let defaultOperator =
                                                      "equals";
                                                    let defaultValue = "";

                                                    if (
                                                      newType ===
                                                      "lastMessageSender"
                                                    ) {
                                                      defaultOperator =
                                                        "greaterThan";
                                                      defaultValue = new Date()
                                                        .toISOString()
                                                        .slice(0, 16); // Current date-time
                                                    }

                                                    setFieldValue(
                                                      `conditions.${index}.operator`,
                                                      defaultOperator
                                                    );
                                                    setFieldValue(
                                                      `conditions.${index}.value`,
                                                      defaultValue
                                                    );
                                                    setFieldValue(
                                                      `conditions.${index}.deleteOnNewMessage`,
                                                      false
                                                    );
                                                  }}
                                                >
                                                  <MenuItem value="labels">
                                                    Labels
                                                  </MenuItem>
                                                  <MenuItem value="contactNumber">
                                                    Contact Number
                                                  </MenuItem>
                                                </Field>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                              <FormControl
                                                fullWidth
                                                className={classes.formControl}
                                              >
                                                <InputLabel>
                                                  Operator
                                                </InputLabel>
                                                <Field
                                                  as={Select}
                                                  name={`conditions.${index}.operator`}
                                                >
                                                  <MenuItem value="equals">
                                                    Equals
                                                  </MenuItem>
                                                  <MenuItem value="notEquals">
                                                    Not Equals
                                                  </MenuItem>
                                                </Field>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                              <Field
                                                as={TextField}
                                                type={
                                                  values.conditions[index]
                                                    .type ===
                                                  "lastMessageSender"
                                                    ? "datetime-local"
                                                    : "text"
                                                }
                                                fullWidth
                                                label={i18n.t(
                                                  "autoFollowUpRuleModal.form.conditions.value"
                                                )}
                                                name={`conditions.${index}.value`}
                                                error={
                                                  touched.conditions?.[index]
                                                    ?.value &&
                                                  Boolean(
                                                    errors.conditions?.[index]
                                                      ?.value
                                                  )
                                                }
                                                helperText={
                                                  touched.conditions?.[index]
                                                    ?.value &&
                                                  errors.conditions?.[index]
                                                    ?.value
                                                }
                                                className={classes.textField}
                                              />
                                            </Grid>
                                            {values.conditions[index]?.type ===
                                              "labels" && (
                                              <Grid item xs={12} sm={2}>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Field
                                                    as={Checkbox}
                                                    name={`conditions.${index}.deleteOnNewMessage`}
                                                    color="primary"
                                                    checked={
                                                      values.conditions[index]
                                                        ?.deleteOnNewMessage
                                                    }
                                                  />
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Typography variant="caption">
                                                      {i18n.t(
                                                        "autoFollowUpRuleModal.form.conditions.deleteOnNewMessage"
                                                      )}
                                                    </Typography>
                                                    <TooltipHelp
                                                      tooltipOpen={
                                                        tooltipOpenArray[index]
                                                      }
                                                      handleTooltipClose={() =>
                                                        handleTooltipClose()
                                                      }
                                                      handleTooltipToggle={() =>
                                                        handleTooltipToggle(
                                                          index
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </Grid>
                                            )}
                                          </Grid>
                                          <IconButton
                                            onClick={() => remove(index)}
                                            size="small"
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </ListItem>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </List>
                              )}
                            </Droppable>
                          </DragDropContext>
                          <Button
                            startIcon={<AddIcon />}
                            onClick={() => {
                              push({
                                type: "labels",
                                operator: "equals",
                                value: "",
                              });
                            }}
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                          >
                            {i18n.t(
                              "autoFollowUpRuleModal.form.conditions.add"
                            )}
                          </Button>
                        </>
                      )}
                    </FieldArray>

                    <Divider className={classes.divider} />
                    <Typography variant="body1">
                      {i18n.t("autoFollowUpRuleModal.form.actions.title")}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {i18n.t("autoFollowUpRuleModal.form.actions.description")}
                    </Typography>
                    <FieldArray
                      name="actions"
                      render={(arrayHelpers) => (
                        <>
                          {values.actions && values.actions.length > 0
                            ? values.actions.map((action, index) => (
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginTop: 10 }}
                                  key={index}
                                  alignItems="center"
                                >
                                  <Grid item xs={12} sm={3}>
                                    <FormControl
                                      fullWidth
                                      className={classes.formControl}
                                    >
                                      <InputLabel>
                                        {i18n.t(
                                          "autoFollowUpRuleModal.form.actions.type"
                                        )}
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`actions.${index}.type`}
                                      >
                                        <MenuItem value="modifyTicketLabels">
                                          {i18n.t(
                                            "autoFollowUpRuleModal.form.actions.modifyTicketLabels"
                                          )}
                                        </MenuItem>
                                        <MenuItem value="modifyContactLabels">
                                          {i18n.t(
                                            "autoFollowUpRuleModal.form.actions.modifyContactLabels"
                                          )}
                                        </MenuItem>
                                      </Field>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={2}>
                                    <FormControl
                                      fullWidth
                                      className={classes.formControl}
                                    >
                                      <InputLabel>
                                        {i18n.t(
                                          "autoFollowUpRuleModal.form.actions.operation"
                                        )}
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`actions.${index}.operation`}
                                      >
                                        <MenuItem value="addLabel">
                                          {i18n.t(
                                            "autoFollowUpRuleModal.form.actions.addLabel"
                                          )}
                                        </MenuItem>
                                        <MenuItem value="removeLabel">
                                          {i18n.t(
                                            "autoFollowUpRuleModal.form.actions.removeLabel"
                                          )}
                                        </MenuItem>
                                        <MenuItem value="updateLabel">
                                          {i18n.t(
                                            "autoFollowUpRuleModal.form.actions.updateLabel"
                                          )}
                                        </MenuItem>
                                      </Field>
                                    </FormControl>
                                  </Grid>
                                  {values.actions[index].operation ===
                                    "updateLabel" && (
                                    <>
                                      <Grid item xs={12} sm={3}>
                                        <Field
                                          as={TextField}
                                          fullWidth
                                          label={i18n.t(
                                            "autoFollowUpRuleModal.form.actions.from"
                                          )}
                                          name={`actions.${index}.fromValue`}
                                          error={
                                            touched.actions?.[index]
                                              ?.fromValue &&
                                            Boolean(
                                              errors.actions?.[index]?.fromValue
                                            )
                                          }
                                          helperText={
                                            touched.actions?.[index]
                                              ?.fromValue &&
                                            errors.actions?.[index]?.fromValue
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <Field
                                          as={TextField}
                                          fullWidth
                                          label={i18n.t(
                                            "autoFollowUpRuleModal.form.actions.to"
                                          )}
                                          name={`actions.${index}.toValue`}
                                          error={
                                            touched.actions?.[index]?.toValue &&
                                            Boolean(
                                              errors.actions?.[index]?.toValue
                                            )
                                          }
                                          helperText={
                                            touched.actions?.[index]?.toValue &&
                                            errors.actions?.[index]?.toValue
                                          }
                                        />
                                      </Grid>
                                    </>
                                  )}
                                  {(values.actions[index].operation ===
                                    "addLabel" ||
                                    values.actions[index].operation ===
                                      "removeLabel") && (
                                    <>
                                      <Grid item xs={12} sm={6}>
                                        <Field
                                          as={TextField}
                                          fullWidth
                                          label={i18n.t(
                                            "autoFollowUpRuleModal.form.actions.label"
                                          )}
                                          name={`actions.${index}.toValue`}
                                          error={
                                            touched.actions?.[index]?.to &&
                                            Boolean(errors.actions?.[index]?.to)
                                          }
                                          helperText={
                                            touched.actions?.[index]?.to &&
                                            errors.actions?.[index]?.to
                                          }
                                        />
                                      </Grid>
                                    </>
                                  )}
                                  <Grid item xs={12} sm={1} align="right">
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                      style={{ paddingRight: 16 }}
                                      size="small"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ))
                            : null}
                          <Button
                            startIcon={<AddIcon />}
                            onClick={() =>
                              arrayHelpers.push({
                                type: "modifyTicketLabels",
                                operation: "updateLabel",
                                fromValue: "",
                                toValue: "",
                              })
                            }
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                          >
                            {i18n.t("autoFollowUpRuleModal.form.actions.add")}
                          </Button>
                        </>
                      )}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      {i18n.t("cancel")}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {i18n.t("save")}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default AutoFollowUpRuleModal;
