import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  messageReaction: {
    display: "inline-flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    padding: "1px 6px",
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.3)",
  },
  count: {
    fontSize: "0.75rem",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
  },
}));

const MessageReaction = ({ reaction }) => {
  const classes = useStyles();
  const reactionCount = reaction.reduce((count, r) => {
    count[r.reaction] = (count[r.reaction] || 0) + 1;
    return count;
  }, {});

  return (
    <div>
      {Object.entries(reactionCount).map(([emoji, count]) => (
        <span key={emoji} className={classes.messageReaction}>
          <span className={classes.emoji}>{emoji}</span>
          {count > 1 && (
            <Typography variant="caption" className={classes.count}>
              {count}
            </Typography>
          )}
        </span>
      ))}
    </div>
  );
};

export default MessageReaction;