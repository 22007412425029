import React from "react";
import { toast } from "react-toastify";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const ReactionModal = ({ open, onClose, message }) => {
  const classes = useStyles();

  const handleAddEmoji = async (e) => {
    onClose(false);

    let emoji = e.native;

    try {
      await api.post(`/messages/${message.id}/react`, {
        reaction: emoji,
        channel: message.channel,
      });

      toast.success(i18n.t("reactionModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={() => onClose(false)} maxWidth="sm">
        <DialogTitle>{i18n.t("reactionModal.title")}</DialogTitle>
        <Picker
          perLine={16}
          data={data}
          showPreview={false}
          showSkinTones={false}
          onEmojiSelect={handleAddEmoji}
        />
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            {i18n.t("reactionModal.buttons.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReactionModal;
